.pagination {
    display: flex;
    justify-content: space-around;
    list-style: none;
    cursor: pointer;
    word-break:keep-all;
    padding: 0;
}

.pagination a {
    padding: 7px 10px;
    color: #383E47;
}

.pagination__link {
    font-weight: bold;
}

.pagination__link--active a {
    color: #fff;
    background: #8F99A8;
}

.pagination__link--disabled a {
    color: rgb(198, 197, 202);
}