@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/table/lib/css/table.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

html, body {
    height: 100%;
    margin: 0;
    padding: 0;

    ::-webkit-scrollbar {
        display: none;
    }
}

.App {

}

img {
    image-orientation: from-image !important;
}

table {
    border-top: 1px solid #404854;
    border-bottom: 1px solid #404854;
    /*word-break:keep-all;*/
}

td {
    vertical-align: middle !important;
    border-bottom: 0.5px solid #5F6B7C !important;
}

th {
    border-bottom: 0.5px solid #404854;
    background-color: #d9dce8;
    padding: 10px 3px !important;
}

.table-info * {
    text-align: center !important;
    vertical-align: middle !important;
    word-break:keep-all;
}

.table-info {
    text-align: center !important;
    vertical-align: middle !important;
}

.table-info td {
    padding: 8px 7px !important;
    border-right: 0.5px solid #404854;
    letter-spacing: -0.5px;
    font-weight: 500;
    white-space: nowrap;
}

.table-info tr:nth-child(even) {
    background-color: #F6F7F9;
}

.table-info tr:hover {
    background-color: #cae1ff;
}

.table-info .order-tr:hover {
    background-color: #e12525;
}

.table-info tr {
    cursor: pointer;
}

td:last-child {
    border-right: none;
}


tr:last-child td{
    border-bottom: none !important;
}